import React from "react";
import { RenderLeafProps } from "slate-react";
import styled from "styled-components";

import { DARK_THEME } from "../../theme/themes/darkTheme";

export const Leaf = styled.span<{
  $bold?: boolean;
  $italic?: boolean;
  $code?: boolean;
  $underline?: boolean;
  $selection?: boolean;
  $strikethrough?: boolean;
}>`
  font-weight: ${({ $bold, theme }) =>
    $bold ? theme.fontWeight.SEMI_BOLD : "inherit"};
  font-family: ${({ $code, theme }) =>
    $code ? theme.fontFamily.MONO : theme.fontFamily.DEFAULT};
  font-style: ${({ $italic }) => ($italic ? "italic" : "normal")};

  ${({ $code, theme }) =>
    $code &&
    `
    font-size: 0.9em;

    background-color: ${theme.codeBackground};

    padding-top: 1px;
    padding-bottom: 1px;
    &:first-child {
      border-top-left-radius: ${theme.borderRadius};
      border-bottom-left-radius: ${theme.borderRadius};
      padding-left: 3px;
    }
    &:last-child {
      padding-right: 3px;
      border-top-right-radius: ${theme.borderRadius};
      border-bottom-right-radius: ${theme.borderRadius};
    }
  `}

  ${({ $strikethrough, $underline }) =>
    ($underline || $strikethrough) &&
    `text-decoration: ${$underline ? "underline" : ""} ${
      $strikethrough ? "line-through" : ""
    };`}

  ${({ $selection, theme }) =>
    $selection &&
    `background-color: ${
      theme === DARK_THEME ? "rgb(87,125,169)" : "highlight"
    }; padding: 2px 0;`}
`;

export const renderLeaf = ({
  attributes,
  children,
  leaf: {
    bold,
    code,
    italic,
    selection,
    strikeThrough,
    strikethrough,
    underline,
  },
}: RenderLeafProps): JSX.Element => {
  return (
    <Leaf
      {...attributes}
      $bold={bold}
      $code={code}
      $italic={italic}
      $selection={selection}
      $strikethrough={strikethrough ?? strikeThrough}
      $underline={underline}
      spellCheck={!code}
    >
      {children}
    </Leaf>
  );
};
