import { gql } from "@apollo/client";
import {
  CustomElement,
  DataframeMention,
  GroupMention,
  HexMention,
  HexType,
  HexVersionId,
  LinkElement,
  MentionElement,
  MentionedDataframeName,
  RichTextBlockquote,
  RichTextCodeblock,
  RichTextDocument,
  RichTextH1,
  RichTextH2,
  RichTextH3,
  RichTextH4,
  RichTextH5,
  RichTextH6,
  RichTextHR,
  RichTextHeading,
  RichTextImage,
  RichTextLI,
  RichTextLIC,
  RichTextLink,
  RichTextMentionElementType,
  RichTextMentionInputElementType,
  RichTextOL,
  RichTextP,
  RichTextPLegacy,
  RichTextReference,
  RichTextUL,
  TableMention,
  UserMention,
  guardNever,
} from "@hex/common";
import { TRenderElementProps } from "@udecode/plate-common";
import { rgba } from "polished";
import React, {
  CSSProperties,
  ComponentType,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useReadOnly, useSelected } from "slate-react";
import styled, { css, useTheme } from "styled-components";

import { useScopeSelector } from "../../appsession-multiplayer/state-hooks/scopeStateHooks.js";
import { HexButton, HexTooltip } from "../../hex-components";
import { useHexFlag } from "../../util/useHexFlags.js";
import { ProjectTooltip } from "../hex/ProjectTooltip.js";
import {
  ComponentIconV2,
  DataframeIcon,
  ProjectIcon,
  TableCellIcon,
} from "../icons/CustomIcons.js";
import {
  MARKDOWN_AND_RICH_TEXT_HEADING_LINK_CLASSNAME,
  ScrollToLinkAnchor,
} from "../markdown/ScrollToLinkAnchor";
import { UserTooltip } from "../user/UserTooltip";

import {
  useMentionGroupInfoQuery,
  useMentionHexInfoQuery,
  useMentionTableSummaryByIdQuery,
  useMentionUserInfoQuery,
} from "./elementRenderers.generated";
import { Leaf } from "./leafRenderer.js";
import { usePlateRichTextToolbarMode } from "./PlateRichTextContext";
import { RichTextImageElement } from "./plugins/image/RichTextImageElement";
import {
  DataframeMentionDetails,
  TableMentionDetails,
} from "./plugins/MentionDetails.js";
import { RichTextReferenceElement } from "./plugins/reference/RichTextReferenceElement";
import { useRichTextMentionContext } from "./plugins/RichTextMentionContext.js";
import { RichTextToolbarMode } from "./toolbar/RichTextToolbarMode";
import { getRichTextHeadingUrl } from "./utils/getRichTextHeadingUrl";

export type TRenderCustomElementProps = TRenderElementProps<
  RichTextDocument,
  CustomElement
>;

const renderElement = ({
  hexVersionId,
  props,
}: {
  props: TRenderCustomElementProps;
  hexVersionId?: HexVersionId;
}): JSX.Element => {
  switch (props.element.type) {
    case RichTextMentionElementType.value:
      return <MentionElementRenderer {...props} element={props.element} />;
    case RichTextP.value:
    case RichTextPLegacy.value:
    case RichTextLIC.value:
      return <ParagraphElementRenderer {...props} />;
    case RichTextOL.value:
      return <OrderedListRenderer {...props} />;
    case RichTextUL.value:
      return <UnorderedListRenderer {...props} />;
    case RichTextLI.value:
      return <ListItemRenderer {...props} />;
    case RichTextLink.value:
      return <LinkRenderer {...props} />;
    case RichTextBlockquote.value:
      return <BlockquoteRenderer {...props} />;
    case RichTextHR.value:
      return <HRRenderer {...props} />;
    case RichTextH1.value:
    case RichTextH2.value:
    case RichTextH3.value:
    case RichTextH4.value:
    case RichTextH5.value:
    case RichTextH6.value:
      return <HeadingElementRenderer {...props} />;
    case RichTextReference.value:
      return <RichTextReferenceElement {...props} />;
    case RichTextImage.value:
      return <RichTextImageElement {...props} hexVersionId={hexVersionId} />;
    case RichTextCodeblock.value:
      return <CodeblockRenderer {...props} />;
    case RichTextMentionInputElementType.value:
      return <ParagraphElementRenderer {...props} />;
    default:
      guardNever(props.element, (props.element as { type: string }).type);
      return <ParagraphElementRenderer {...props} />;
  }
};

export const getRenderElement = ({
  hexVersionId,
}: {
  hexVersionId?: HexVersionId;
}) => {
  return (props: TRenderElementProps) =>
    renderElement({
      props: props as TRenderCustomElementProps,
      hexVersionId,
    });
};

const StyledParagraph = styled.p`
  && {
    position: relative;
  }
`;

const StyledCodeBlock = styled.pre`
  position: relative;
  padding: 15px 20px;
  ${Leaf} {
    font-size: ${({ theme }) => theme.fontSize.DEFAULT};
    font-family: ${({ theme }) => theme.fontFamily.MONO};
  }
`;

const InlineProjectMentionIconStyles = css`
  margin-right: 2px;
  padding-bottom: 1px;
`;

export const ParagraphElementRenderer = (
  props: TRenderElementProps,
): JSX.Element => {
  // fudge types here to get around issue with supporting both slate and plate at once
  const { attributes, children, style } = props as TRenderCustomElementProps & {
    style?: CSSProperties;
  };
  return (
    <StyledParagraph {...attributes} style={style}>
      {children}
    </StyledParagraph>
  );
};

gql`
  query MentionUserInfo($userId: UserId!) {
    userById(userId: $userId) {
      id
      name
      email
      imageUrl
    }
  }

  query MentionGroupInfo($groupId: GroupId!) {
    groupById(groupId: $groupId) {
      id
      name
    }
  }

  query MentionHexInfo($hexId: HexId!) {
    hexById(hexId: $hexId) {
      id
      hexType
      currentDraft {
        id
        title
      }
      ...ProjectToolTipFragment
    }
  }
`;

gql`
  query MentionTableSummaryById($dataSourceTableId: DataSourceTableId!) {
    dataSourceTableById(dataSourceTableId: $dataSourceTableId) {
      id
      name
      dbtDescription
      comment
      pinned
      dataSourceSchema {
        id
        name
        dataSourceDatabase {
          id
          name
          dataConnectionId
        }
      }
      metadata {
        id
        magicDescription
        status {
          ...StatusFragment
        }
      }
    }
  }
`;

interface MentionElementRendererProps extends TRenderElementProps {
  element: MentionElement;
}

export const MentionElementRenderer: ComponentType<
  MentionElementRendererProps
> = ({ attributes, children, element }) => {
  const mentionType = element.mentionType;
  switch (mentionType) {
    case "group":
      return (
        <GroupMentionElementRenderer {...{ attributes, children, element }} />
      );
    case "user":
      return (
        <UserMentionElementRenderer {...{ attributes, children, element }} />
      );
    case "table":
      return (
        <TableMentionElementRenderer {...{ attributes, children, element }} />
      );
    case "dataframe":
      return (
        <DataframeMentionElementRenderer
          {...{ attributes, children, element }}
        />
      );
    case "hex":
      return (
        <HexMentionElementRenderer {...{ attributes, children, element }} />
      );
    default:
      guardNever(mentionType, mentionType);
      return null;
  }
};
interface UserMentionElementRendererProps extends TRenderElementProps {
  element: UserMention;
}

const MentionTag = styled.span<{ $error: boolean }>`
  ${({ $error, theme }) =>
    `
    font-size: inherit;
    background-color: ${
      $error ? theme.backgroundColor.MUTED : rgba(theme.intent.PRIMARY, 0.1)
    };
    color: ${$error ? theme.fontColor.DEFAULT : theme.intent.PRIMARY};
    border-radius: ${theme.borderRadius};
    padding: 0 3px 1px 2px;
  `}
`;

const UserMentionElementRenderer: ComponentType<
  UserMentionElementRendererProps
> = ({ attributes, children, element }) => {
  const { data, error } = useMentionUserInfoQuery({
    variables: {
      userId: element.userId,
    },
  });
  const resolvedName =
    (data?.userById.name || data?.userById.email) ?? element.previewText;

  const content = (
    <MentionTag {...attributes} $error={error != null}>
      @{resolvedName}
      {children}
    </MentionTag>
  );

  return data != null ? (
    <UserTooltip
      email={data.userById.email}
      imageUrl={data.userById.imageUrl ?? undefined}
      name={data.userById.name ?? undefined}
      toolTipTargetTagName="span"
    >
      {content}
    </UserTooltip>
  ) : (
    content
  );
};

interface GroupMentionElementRendererProps extends TRenderElementProps {
  element: GroupMention;
}
const GroupMentionElementRenderer: ComponentType<
  GroupMentionElementRendererProps
> = ({ attributes, children, element }) => {
  const { data, error } = useMentionGroupInfoQuery({
    variables: {
      groupId: element.groupId,
    },
  });

  const content = (
    <MentionTag {...attributes} $error={error != null}>
      @{data?.groupById.name ?? element.previewText}
      {children}
    </MentionTag>
  );

  // A little hacky but using existing UserTooltip component since we want the
  // tooltips to look the same, just with the group info replacing the email
  return data != null ? (
    <UserTooltip
      name={data.groupById.name ?? undefined}
      toolTipTargetTagName="span"
    >
      {content}
    </UserTooltip>
  ) : (
    content
  );
};

interface HexMentionElementRendererProps extends TRenderElementProps {
  element: HexMention;
}
const HexMentionElementRenderer: ComponentType<
  HexMentionElementRendererProps
> = ({ attributes, children, element }) => {
  const theme = useTheme();
  const { data, error } = useMentionHexInfoQuery({
    variables: {
      hexId: element.hexId,
    },
  });
  const resolvedName = data?.hexById.currentDraft.title ?? element.previewText;
  const resolvedType = data?.hexById.hexType ?? element.hexType;
  const resolvedColor =
    error != null ? theme.fontColor.DEFAULT : theme.intent.PRIMARY;

  const content = (
    <MentionTag {...attributes} $error={error != null}>
      {resolvedType === HexType.PROJECT ? (
        <ProjectIcon
          color={resolvedColor}
          css={InlineProjectMentionIconStyles}
        />
      ) : (
        <ComponentIconV2
          color={resolvedColor}
          css={InlineProjectMentionIconStyles}
        />
      )}
      {resolvedName}
      {children}
    </MentionTag>
  );

  return data != null ? (
    <ProjectTooltip data={data.hexById} toolTipTargetTagName="span">
      {content}
    </ProjectTooltip>
  ) : (
    content
  );
};

interface TableMentionElementRendererProps extends TRenderElementProps {
  element: TableMention;
}
const TableMentionElementRenderer: ComponentType<
  TableMentionElementRendererProps
> = ({ attributes, children, element }) => {
  const theme = useTheme();
  const { showInDataBrowser } = useRichTextMentionContext();
  const magicMentionDetails = useHexFlag("magic-mention-details");
  const { data, error } = useMentionTableSummaryByIdQuery({
    variables: {
      dataSourceTableId: element.tableId,
    },
  });
  const databaseName =
    data?.dataSourceTableById.dataSourceSchema.dataSourceDatabase.name;
  const table = data?.dataSourceTableById;

  const content = (
    <MentionTag {...attributes} $error={error != null}>
      <TableCellIcon
        color={error != null ? theme.fontColor.DEFAULT : theme.intent.PRIMARY}
        css={`
          margin-bottom: 1.5px;
          margin-right: 3px;
        `}
      />
      {data
        ? `${databaseName ? databaseName + "." : ""}${
            data.dataSourceTableById.dataSourceSchema.name
          }.${data.dataSourceTableById.name}`
        : element.previewText}
      {children}
    </MentionTag>
  );

  const tooltip = table && magicMentionDetails && (
    <TableMentionDetails
      mention={{
        tableName: table.name,
        schemaName: table.dataSourceSchema.name,
        databaseName: table.dataSourceSchema.dataSourceDatabase.name,
        mentionType: "table",
        id: table.id,
        connectionId:
          table.dataSourceSchema.dataSourceDatabase.dataConnectionId,
        description:
          table.metadata?.magicDescription ||
          table.dbtDescription ||
          table.comment,
        pinned: table.pinned,
        status: table.metadata?.status ?? null,
      }}
      showInDataBrowser={showInDataBrowser}
    />
  );

  if (tooltip) {
    return (
      <HexTooltip
        content={tooltip}
        hoverCloseDelay={200}
        interactionKind="hover"
        maxWidthOverride={340}
        placement="bottom-start"
        useAppTheme={true}
      >
        {content}
      </HexTooltip>
    );
  } else {
    return content;
  }
};

interface DataframeMentionElementRendererProps extends TRenderElementProps {
  element: DataframeMention;
}
const DataframeMentionElementRenderer: ComponentType<
  DataframeMentionElementRendererProps
> = ({ attributes, children, element }) => {
  const dataframe = useScopeSelector({
    selector: (x) => x[element.name],
  });
  const theme = useTheme();
  const magicMentionDetails = useHexFlag("magic-mention-details");
  const content = (
    <MentionTag {...attributes} $error={dataframe == null}>
      <DataframeIcon
        color={
          dataframe == null ? theme.fontColor.DEFAULT : theme.intent.PRIMARY
        }
        css={`
          margin-bottom: 1.5px;
          margin-right: 3px;
        `}
      />
      {dataframe?.name ?? element.previewText}
      {children}
    </MentionTag>
  );

  const tooltip = dataframe && magicMentionDetails && (
    <DataframeMentionDetails
      mention={{
        mentionType: "dataframe",
        id: dataframe.name,
        name: dataframe.name as MentionedDataframeName,
        connectionId: null,
        columns: Object.keys(dataframe.dataFrameSchema?.columns ?? {}),
      }}
    />
  );

  if (tooltip) {
    return (
      <HexTooltip
        content={tooltip}
        hoverCloseDelay={200}
        interactionKind="hover"
        maxWidthOverride={340}
        placement="bottom-start"
        useAppTheme={true}
      >
        {content}
      </HexTooltip>
    );
  } else {
    return content;
  }
};

export const HeadingElementRenderer = (
  props: TRenderElementProps,
): JSX.Element => {
  const { attributes, children: childrenProps, element } = props;
  const isSelected = useSelected();

  const url = useMemo(() => {
    return getRichTextHeadingUrl(element as CustomElement);
  }, [element]);

  if (!RichTextHeading.guard(element.type)) {
    return <ParagraphElementRenderer {...props} />;
  }

  // In Firefox, the heading anchor rendered next to the heading text
  // got included as part of the selection, which resulted in
  // (1) slate erroring when trying to figure out what to do with
  //     that node that doesn't exist in its document object and
  // (2) cmd+rightarrow not working for moving the cursor to the end
  //     of the line.
  // Fixed the issue by
  // (1) setting contentEditable=false for the element
  //     (which fixes the slate error issue
  // (2) hiding the component when the cursor is in the heading
  //     (which fixes the selection navigation issue), as users
  //     rarely click on the nav button when editing heading text.
  const children = (
    <>
      {childrenProps}
      {url != null && !isSelected && (
        <ScrollToLinkAnchor
          className={MARKDOWN_AND_RICH_TEXT_HEADING_LINK_CLASSNAME}
          contentEditable={false}
          href={url}
          tabIndex={-1}
        />
      )}
    </>
  );

  if (RichTextH1.guard(element.type)) {
    return <h1 {...attributes}>{children}</h1>;
  } else if (RichTextH2.guard(element.type)) {
    return <h2 {...attributes}>{children}</h2>;
  } else if (RichTextH3.guard(element.type)) {
    return <h3 {...attributes}>{children}</h3>;
  } else if (RichTextH4.guard(element.type)) {
    return <h4 {...attributes}>{children}</h4>;
  } else if (RichTextH5.guard(element.type)) {
    return <h5 {...attributes}>{children}</h5>;
  } else if (RichTextH6.guard(element.type)) {
    // Convert h6 to h5 as we don't have a h6 style
    return <h5 {...attributes}>{children}</h5>;
  } else {
    return <ParagraphElementRenderer {...props} />;
  }
};

export const OrderedListRenderer = (
  props: TRenderElementProps,
): JSX.Element => {
  const { attributes, children, element } = props;
  if (!RichTextOL.guard(element.type)) {
    return <ParagraphElementRenderer {...props} />;
  }
  return <ol {...attributes}>{children}</ol>;
};

export const UnorderedListRenderer = (
  props: TRenderElementProps,
): JSX.Element => {
  const { attributes, children, element } = props;
  if (!RichTextUL.guard(element.type)) {
    return <ParagraphElementRenderer {...props} />;
  }
  return <ul {...attributes}>{children}</ul>;
};

export const ListItemRenderer = (props: TRenderElementProps): JSX.Element => {
  const { attributes, children, element } = props;
  if (!RichTextLI.guard(element.type)) {
    return <ParagraphElementRenderer {...props} />;
  }
  return <li {...attributes}>{children}</li>;
};

const LinkTooltip = styled(HexTooltip)`
  && {
    display: inline;
  }
`;

const LinkEditButton = styled(HexButton)`
  margin-left: 4px;

  word-break: none;

  cursor: pointer;
`;

const LinkTooltipContent = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const LinkTooltipUrl = styled.span`
  overflow: hidden;

  text-overflow: ellipsis;
`;

export const LinkRenderer = (props: TRenderCustomElementProps): JSX.Element => {
  const { attributes, children, element } = props;
  const isReadOnly = useReadOnly();

  const normalizedUrl = useMemo(() => {
    if (element.type !== RichTextLink.value) {
      return null;
    }
    const url = element.url?.toLowerCase() ?? "";
    // This check protects us from https://nvd.nist.gov/vuln/detail/CVE-2023-34245
    return !(url.startsWith("https://") || url.startsWith("http://"))
      ? `https://${element.url}`
      : element.url;
  }, [element]);

  if (!LinkElement.guard(element)) {
    return <span {...props} />;
  }

  if (isReadOnly || normalizedUrl == null) {
    return (
      <a {...attributes} href={element.url} rel="noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return (
    <PlateLinkRenderer
      {...props}
      element={element}
      normalizedUrl={normalizedUrl}
    />
  );
};

const PlateLinkRenderer = (
  props: TRenderElementProps & {
    normalizedUrl: string;
    element: LinkElement;
  },
): JSX.Element => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const { attributes, children, element, normalizedUrl } = props;
  const [toolbarMode, setToolbarMode] = usePlateRichTextToolbarMode();

  const onLinkClick = useCallback(() => {
    if (normalizedUrl != null) {
      window.open(normalizedUrl, "_blank");
    }
  }, [normalizedUrl]);

  const onEditClick = useCallback(() => {
    const anchor = spanRef.current;
    if (anchor != null) {
      const range = document.createRange();
      range.selectNodeContents(anchor);
      const selection = window.getSelection();
      if (selection != null) {
        selection.removeAllRanges();
        selection.addRange(range);
        // selecting text and re-render in the rich text editor (due to
        // the editor's subscription to the PlateRichTextContext) at the
        // same time results in a race condition that breaks selections,
        // so we let the selection run before updating the toolbar mode
        // (and thereby delay the editor re-render)
        setTimeout(() => {
          setToolbarMode(RichTextToolbarMode.LINK);
        }, 10);
      }
    }
  }, [setToolbarMode]);

  return (
    <LinkTooltip
      content={
        <LinkTooltipContent>
          <LinkTooltipUrl>{element.url}</LinkTooltipUrl>
          <LinkEditButton minimal={true} small={true} onClick={onEditClick}>
            Edit
          </LinkEditButton>
        </LinkTooltipContent>
      }
      disabled={toolbarMode === RichTextToolbarMode.NONE}
      interactionKind="hover"
      placement="bottom"
    >
      <span ref={spanRef}>
        <a
          {...attributes}
          href={normalizedUrl}
          // unfortunately we need to manually trigger this on click as slate.js disables
          // navigation from the editor via link clicking when the editor isn't in readonly mode
          onClick={onLinkClick}
        >
          {children}
        </a>
      </span>
    </LinkTooltip>
  );
};

export const BlockquoteRenderer = (props: TRenderElementProps): JSX.Element => {
  const { attributes, children, element } = props;
  if (!RichTextBlockquote.guard(element.type)) {
    return <ParagraphElementRenderer {...props} />;
  }
  return <blockquote {...attributes}>{children}</blockquote>;
};

export const CodeblockRenderer = (props: TRenderElementProps): JSX.Element => {
  const { attributes, children, element } = props;
  if (!RichTextCodeblock.guard(element.type)) {
    return <ParagraphElementRenderer {...props} />;
  }
  return <StyledCodeBlock {...attributes}>{children}</StyledCodeBlock>;
};

export const HRRenderer = (props: TRenderElementProps): JSX.Element => {
  const { attributes, children, element } = props;
  const isSelected = useSelected();
  const theme = useTheme();

  if (!RichTextHR.guard(element.type)) {
    return <ParagraphElementRenderer {...props} />;
  }

  return (
    <div {...attributes}>
      <hr
        contentEditable={false}
        css={`
          box-shadow: ${isSelected ? theme.boxShadow.BUTTON : ""};
        `}
      />
      {children}
    </div>
  );
};
